import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import NavigationBar from './navbar'
import Positions from './Positions';
import Stocks from './Stocks';
import { connect } from 'react-redux'
import preval from 'preval.macro'


class App extends React.Component {

    render() {
        return (
            <HashRouter>
                <NavigationBar></NavigationBar>
                <div className="content">
                    <Switch>
                        <Route exact path="/" component={Positions} />
                        <Route path="/stocks" component={Stocks} />
                    </Switch>
                </div>
	 <p>
            Build Date: {preval`module.exports = new Date().toLocaleString();`}.
          </p>
            </HashRouter>
        )
    }
}

export default connect(
    (state) => {
        return {
            rowData: state.rowData,
            footerData: state.footerData,
            stocksData: state.stocksData,
        }
    }
)(App);
